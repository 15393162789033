import React from 'react';

import Layout from 'src/layout/Layout';
import AnalyticsScript from 'src/components/Analytics';

const articles = () => {
  return (
    <Layout>
      <></>
      <AnalyticsScript />
    </Layout>
  );
};

export default articles;
